<template>
  <div class="shilut-deals-db">
    <div class="shilut-deals-db-header">
        <h1>בחירת מבצעים</h1>
    </div>
    <div class="shilut-deals-db-tools">
        <el-select style="margin-right:5px;" v-model="selected_department" class="m-2" placeholder="מחלקה" size="large">
            <el-option
            v-for="department in departments"
            :key="department.uid"
            :value="department.name"
            />
        </el-select>
        <el-input  style="width:30%; margin-right:5px;" v-model="search" placeholder="חיפוש" />
        <el-button style="margin-right:5px;" @click="handle_reload_deals" type="success">טען שלטים</el-button>
        <div @click="$emit('back')" class="back-btn">
            <i class="material-icons">arrow_back</i>
        </div>
    </div>
    <div class="shilut-deals-db-content">
        <table>
            <tr>
                <th style="cursor:pointer;" @click="handleSortTable('קוד', !selectedSorted.order)">קוד מבצע</th>
                <th style="cursor:pointer;" @click="handleSortTable('מחלקה', !selectedSorted.order)">מחלקה</th>
                <th style="cursor:pointer;" @click="handleSortTable('עברית', !selectedSorted.order)">שם מבצע עברית</th>
                <th style="cursor:pointer;" @click="handleSortTable('לועזי', !selectedSorted.order)">שם מבצע לועזי</th>
                <th style="cursor:pointer;" @click="handleSortTable('כמות', !selectedSorted.order)">כמות</th>
                <th style="cursor:pointer;" @click="handleSortTable('מחיר', !selectedSorted.order)">מחיר</th>
                <th style="cursor:pointer;" @click="handleSortTable('מחיר מקורי', !selectedSorted.order)">מחיר מקורי</th>
                <th>מתאריך</th>
                <th>עד תאריך</th>
                <th>מוצרים</th>
                <th>פעולות</th>
            </tr>
            <h1 v-if="deals.length==0">לא קיימים רשומות</h1>
            <template v-for="deal in slice_list" :key="deal.uid">
                <tr>
                    <td>{{deal.deal_uid}}</td>
                    <td>{{deal.department}}</td>
                    <td>{{deal.he_name}}</td>
                    <td>{{deal.ru_name}}</td>
                    <td>{{deal.qnt}}</td>
                    <td>{{deal.price}} &#8362;</td>
                    <td>{{deal.origin_price}} &#8362;</td>
                    <td v-if="deal.start_date">{{new Date(deal.start_date).toLocaleDateString('he')}}</td>
                    <td v-else style="color:red">חסר</td>
                    <td v-if="deal.end_date">{{new Date(deal.end_date).toLocaleDateString('he')}}</td>
                    <td v-else style="color:red;">חסר</td>
                    <td v-if="deal.selected_signs.length>0">{{deal.selected_signs.map(item=>item.barcode).slice(0,4).join(", ")}} {{deal.selected_signs.length>4?'...':''}}</td>
                    <td v-else style="color:red;">חסר/ים</td>
                    <td>
                        <Checkbox v-if="deal.start_date && deal.end_date && deal.selected_signs.length>0" v-model="selectes_deals" :value="deal" />
                        <Checkbox v-else :disabled="true" />
                    </td>
                </tr>
            </template>
        </table>
    </div>
    <div class="shilut-deals-db-pagination">
        <el-pagination 
             v-model:currentPage="pagination.current_page"
             background 
            :page-size="pagination.number_per_page"
            layout="prev,pager,next"
            :total="sorted_list.length" 
        />
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import {get_all_departments} from '../../Scripts/scripts'
import {get_all_deals_from_db} from '../Deals/Scripts/script'
import { computed } from '@vue/runtime-core'
import Checkbox from 'primevue/checkbox';
export default {
    emits:['back','reload_signs'],
    components:{Checkbox},
    setup(_,{emit}){
        const selectes_deals = ref([])
        const selected_department = ref('')
        const search = ref('')
        const departments = ref([])
        const deals = ref([])
      
        const pagination = ref({
            current_page:1,
            number_per_page:50,
        })
        const selectedSorted = ref({
            name: "",
            order: false
        });

        const slice_list = ref(computed(()=>{
            return sorted_list.value.slice((pagination.value.current_page -1 ) * pagination.value.number_per_page,
            ((pagination.value.current_page-1) * pagination.value.number_per_page) + pagination.value.number_per_page)
        }))
        const sorted_list = ref(computed(() => {
            if (selectedSorted.value.name == "")return filter_by_search.value;
            if (selectedSorted.value.name == "קוד") {
                return filter_by_search.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  a.deal_uid > b.deal_uid
                        ? 1
                        : b.deal_uid  > a.deal_uid
                        ? -1
                        : 0;
                    }
                    return a.deal_uid > b.deal_uid
                    ? -1
                    : b.deal_uid  > a.deal_uid
                    ? 1
                    : 0;
                });
            }
            if (selectedSorted.value.name == "מחלקה") {
                return filter_by_search.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  a.department > b.department
                        ? 1
                        : b.department  > a.department
                        ? -1
                        : 0;
                    }
                    return a.department > b.department
                    ? -1
                    : b.department  > a.department
                    ? 1
                    : 0;
                });
            }
            if (selectedSorted.value.name == "עברית") {
                return filter_by_search.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  a.he_name > b.he_name
                        ? 1
                        : b.he_name  > a.he_name
                        ? -1
                        : 0;
                    }
                    return a.he_name > b.he_name
                    ? -1
                    : b.he_name  > a.he_name
                    ? 1
                    : 0;
                });
            }
            if (selectedSorted.value.name == "לועזי") {
                return filter_by_search.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  a.ru_name > b.ru_name
                        ? 1
                        : b.ru_name  > a.ru_name
                        ? -1
                        : 0;
                    }
                    return a.ru_name > b.ru_name
                    ? -1
                    : b.ru_name  > a.ru_name
                    ? 1
                    : 0;
                });
            }
            if (selectedSorted.value.name == "כמות") {
                return filter_by_search.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  a.qnt > b.qnt
                        ? 1
                        : b.qnt  > a.qnt
                        ? -1
                        : 0;
                    }
                    return a.qnt > b.qnt
                    ? -1
                    : b.qnt  > a.qnt
                    ? 1
                    : 0;
                });
            }
            if (selectedSorted.value.name == "מחיר") {
                return filter_by_search.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  a.price > b.price
                        ? 1
                        : b.price  > a.price
                        ? -1
                        : 0;
                    }
                    return a.price > b.price
                    ? -1
                    : b.price  > a.price
                    ? 1
                    : 0;
                });
            }
            if (selectedSorted.value.name == "מחיר מקורי") {
                return filter_by_search.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  a.origin_price > b.origin_price
                        ? 1
                        : b.origin_price  > a.origin_price
                        ? -1
                        : 0;
                    }
                    return a.origin_price > b.origin_price
                    ? -1
                    : b.origin_price  > a.origin_price
                    ? 1
                    : 0;
                });
            }
        }));
        const filter_by_department = ref(computed(()=>{
            if(!selected_department.value || selected_department.value == 'הכל') return deals.value
            return deals.value.filter(deal=>deal.department == selected_department.value)
        }))
        const filter_by_search = ref(computed(()=>{
            if(!search.value) return filter_by_department.value
            return filter_by_department.value.filter(deal=>{
                if(deal.deal_uid.includes(search.value)) return deal
                if(deal.department.includes(search.value)) return deal
                if(deal.he_name.includes(search.value)) return deal
                if(deal.ru_name.includes(search.value)) return deal
                if(deal.qnt == search.value) return deal
                if(deal.price == search.value) return deal
            })
        }))

        const handle_reload_deals = () => {
            if(selectes_deals.value.length > 0){
                const deals = []
                for(const deal of selectes_deals.value){
                    deals.push({
                            first_number:deal.qnt,
                            second_number:deal.price,
                            barcode:deal.selected_signs.map(item=>item.barcode).slice(0,6).join(","),
                            he_name:deal.he_name,
                            ru_name:deal.ru_name,
                            size:deal.size,
                            volume:deal.volume,
                            price:deal.origin_price,
                    })
                }
                emit('reload_signs',deals)
            }
        }
        const handleSortTable = (name, order) => {
            selectedSorted.value.name = name;
            selectedSorted.value.order = order;
        };

        const init = async()=>{
           deals.value = await get_all_deals_from_db()
           departments.value = [{name:'הכל',uid:'asdfasg3276478326'},...await get_all_departments()]
        }

        init()

        return{
            handleSortTable,
            handle_reload_deals,
            selected_department,
            search,
            departments,
            deals,
            filter_by_search,
            selectedSorted,
            sorted_list,
            slice_list,
            pagination,
            selectes_deals,
        }
    }
}
</script>

<style scoped>
    .shilut-deals-db{
        margin: 0 auto;
        width: 100%;
        height: 100%;
        max-width: 1200px;
        padding: 5px;
        color: #fff;
    }
    .shilut-deals-db-header{
        width: 100%;
        height: 50px;
        background: var(--secondary);
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .shilut-deals-db-tools{
        position: relative;
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
    }
    @media only screen and (max-width: 600px){
        .shilut-deals-db-tools > * {
            flex-shrink: 0;
        }
        .shilut-deals-db-tools{
        overflow-y: auto;
        } 
    }
    .shilut-deals-db-content{
        width: 100%;
        height: calc(100% - 150px);
        overflow: auto;
    }
    .shilut-deals-db-pagination{
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    table {
        border-collapse: collapse;
        width: 100%;
    }
    table td, table th {
        border: 1px solid #ddd;
        padding: 2px;       
    }
    table tr:nth-child(even){background-color: #f2f2f2;}
    table tr:nth-child(odd){background-color: #e7e6e6;}
    table tr:hover {background-color: #ddd;}
    table td{
        text-align: center;
        color: var(--main);
        font-size: 18px;
    }
    table th {
        padding-top: 2px;
        padding-bottom: 2px;
        text-align: center;
        background-color: var(--purple);
        color: white;
        position: sticky;
        top: 0;
        font-size: 20px;
    }
    @media only screen and (max-width: 600px){
        table th {
            position: static;
        }
    }
    .icon{
        cursor: pointer;
    }
    .delete-icon{
        color: var(--danger);
        margin: 0 5px;
    }
    .edit-icon{
        color: var(--warning);
    }
    .back-btn{
        margin-right: auto;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        background: var(--danger);
        border-radius: 50%;
        cursor: pointer;
    }


    ::v-deep(.el-pagination.is-background .btn-next i){
        transform: rotate(180deg);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    ::v-deep(.el-pagination.is-background .btn-prev i){
        transform: rotate(180deg);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    ::v-deep(.el-pagination.is-background .el-pager li:not(.disabled).active){
        background: var(--success);
        color: #fff;
        
    }

</style>